<template>
  <b-card>
    <b-card-header><b class="h1">Basic information</b></b-card-header>
    <b-card-body>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <b-form-group label="*Title:" label-cols-md="3">
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    v-model="model.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="*Gallery category:" label-cols-md="3">
                <validation-provider
                  #default="{ errors }"
                  name="Gallery category"
                  rules="required"
                >
                  <v-select
                    v-if="CategoryOptions"
                    v-model="model.gallery_category_id"
                    label="title"
                    :reduce="x => x.id"
                    :options="CategoryOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Position:" label-cols-md="3">
                <b-form-input
                  v-model="model.position"
                  type="number"
                  placeholder="Position"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Display on/off:" label-cols-md="3">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="12">
              <b-form-group label="*Is active:" label-cols-md="3">
                <validation-provider
                  #default="{ errors }"
                  name="Is active"
                  rules="required"
                >
                  <v-select
                    v-model="model.is_active"
                    label="text"
                    :reduce="x => x.value"
                    :options="activeOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          </b-row>
          <b-row>
            <b-col md="3">
              <p><b>*Photos(0)</b></p>
              <p class="text-muted">
                Tips:
              </p>
              <ul>
                <li>- Use natural light and no flash.</li>
                <li>- Include a common object for scale.</li>
                <li>- Show the item being held, worn, or used.</li>
                <li>- Shoot against a clean, simple background.</li>
              </ul>
            </b-col>
            <b-col md="9">
              <div class="d-flex align-items-center">
                <p class="flex-grow-1">
                  We recommend adding 3 more photos
                </p>
                <p @click="handleDeleteAll">Delete All</p>
              </div>
              <b-row>
                <b-col
                  md="3"
                  class="d-flex align-items-center justify-content-center"
                >
                  <div>
                    <b-aspect aspect="1:1">
                      <b-img-lazy
                        v-if="model.avatar && model.avatar !== null"
                        :src="model.avatar"
                        fluid
                        thumbnail
                        rounded
                        class="w-100"
                        style="object-fit: contain;"
                      />
                    </b-aspect>
                  </div>
                </b-col>
                <b-col md="9">
                  <draggable
                    v-model="model.list_thumbnail"
                    tag="ul"
                    class="cursor-move d-flex flex-wrap"
                  >
                    <b-col
                      v-for="(item, index) in model.list_thumbnail"
                      :key="index"
                      class="review-img mb-1"
                      md="3"
                    >
                      <b-button
                        variant="danger"
                        class="review-img__del btn-icon rounded-circle"
                        @click.stop="delImg(index)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <b-aspect aspect="1:1">
                        <b-img-lazy
                          :src="item"
                          fluid
                          thumbnail
                          rounded
                          class="w-100"
                          style="object-fit: contain;"
                        />
                      </b-aspect>
                    </b-col>
                  </draggable>
                </b-col>
                <b-col md="12" class="pt-1">
                  <div
                    class="fade-browse text-center align-items-center justify-content-center w-100 p-2 rounded"
                  >
                    <p class="pt-1">
                      Add upto 12 photos so buyers can see every detail.
                    </p>
                    <div class="d-flex flex-wrap">
                      <b-col md="6">
                        <b-form-file
                          id="input-product"
                          multiple
                          accept=".jpg, .png, .gif"
                          class="img-box"
                          @change="previewFiles"
                        />
                        <label for="input-product" class="btn-add">
                          <feather-icon icon="UploadCloudIcon" />
                          Upload from your PC
                        </label>
                      </b-col>
                      <b-col md="6">
                        <!-- <b-form-file
                      id="input-product"
                      multiple
                      accept=".jpg, .png, .gif"
                      class="img-box"
                      @change="previewFiles"
                    /> -->
                        <label class="btn-add">
                          <feather-icon icon="PlusCircleIcon" />
                          Add from Media center
                        </label>
                      </b-col>
                    </div>
                  </div>
                  <p
                    class="text-muted mt-1 text-center mx-auto"
                    style="font-size: 12px;"
                  >
                    Add up to 12 photos. We don't allow photos with extra
                    borders, text or artwork.
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <!-- Submit -->
            <b-col md="12" class="text-right">
              <b-button
                variant="primary"
                type="submit"
                class="text-right"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardHeader,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormFile,
  BImgLazy,
  BAspect,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BCardHeader,
    BCardBody,
    BForm,
    BFormFile,
    BImgLazy,
    BAspect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    draggable,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {
        avatar: null,
        title: null,
        position: null,
        is_active: true,
        list_thumbnail: [],
        gallery_category_id: null,
      },
      snowOption: {
        theme: 'snow',
      },
      activeOptions: Config.activeOptions,
      CategoryOptions: null,
    }
  },
  created() {
    this.loadBlogCategoryList()
  },
  methods: {
    handlerChangeAvatar(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    handleDeleteAll() {
      this.model.avatar = null
      this.model.list_thumbnail = []
    },
    delImg(index) {
      this.model.list_thumbnail.splice(index, 1)
    },
    async validationForm() {
      const params = {
        template_id: JSON.parse(localStorage.getItem('templateID')).id,
        title: this.model.title,
        position: Number(this.model.position),
        is_active: Number(this.model.is_active === true ? 1 : 0),
        platform_gallery_category_id: this.model.gallery_category_id,
      }
      if (this.model.avatar !== null) {
        params.avatar = this.model.avatar
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.list_thumbnail &&
              this.model.list_thumbnail.length > 0
            ) {
              let listThumbnailId = null
              const listThumbnail = []
              let index = 0
              this.model.list_thumbnail.map(async x => {
                const thumbnail = {
                  template_id: JSON.parse(localStorage.getItem('templateID')).id,
                  table: 'platform_gallery',
                  table_media: 'platform_medias',
                  type: 'desktop',
                  avatar: x,
                  table_field: 'list_thumbnail',
                }
                const resIMG = await Request.post(
                  this.$http,
                  `${process.env.VUE_APP_API_IMG_URL}/create_only_platform`,
                  thumbnail,
                )
                if (resIMG.status === 200) {
                  if (listThumbnailId) {
                    listThumbnailId += `,${resIMG.data.data.id}`
                  } else {
                    listThumbnailId = resIMG.data.data.id
                  }
                  listThumbnail.push(resIMG.data.data.path)
                }
                index += 1
                if (index === this.model.list_thumbnail.length) {
                  params.list_thumbnail_id = listThumbnailId
                  params.list_thumbnail = JSON.stringify(listThumbnail)
                  this.handleCreate(params)
                }
              })
            } else {
              this.handleCreate(params)
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async loadBlogCategoryList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_gallery_categories?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.CategoryOptions = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    previewFiles(input) {
      let i = 0
      if (input.target.files.length + this.model.list_thumbnail.length <= 12) {
        for (i; i < input.target.files.length; i++) {
          const file = input.target.files[i]
          const reader = new FileReader()
          if (i > 0) {
            reader.onload = e => {
              this.model.list_thumbnail.push(e.target.result)
            }
          } else {
            reader.onload = e => {
              if (this.model.avatar !== null) {
                this.model.list_thumbnail.push(e.target.result)
              } else {
                this.model.avatar = e.target.result
              }
            }
          }
          reader.onerror = error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
          reader.readAsDataURL(file)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String('Maximum is 12 picture'),
          },
        })
      }
    },
    async handleCreate(params) {
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_URL}/platform_gallery`,
        params,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Create Gallery success',
            },
          })
          this.$router.push('/gallery/list')
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Login fail',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review-img,
.review-img-avatar {
  position: relative;

  &::before {
    position: absolute;
    content: 'Drag to Sort';
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: rgba(100, 100, 100, 0.75);
    left: 1rem;
    right: 1rem;
    bottom: 0;
    top: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }
  &__del {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    right: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &::before,
    .review-img__del,
    .review-img__del_avatar {
      opacity: 1;
    }
  }
}
.review-img-avatar {
  &::before {
    content: '';
    width: 100%;
    left: 0;
  }
  .review-img__del_avatar {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    z-index: 1;
    width: 100%;
    min-height: 3rem;
    border: 1px solid var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .img-box {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    left: 1rem;
    right: 1rem;
    width: 0;
  }
}
</style>
